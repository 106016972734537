import { Inject, Injectable } from '@angular/core';
import extend from 'lodash-es/extend';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import mixpanel, { Config } from 'mixpanel-browser';
import { BehaviorSubject } from 'rxjs';
import { ROOT_URL } from '@fyle/root-url-token';
import { ExtendedOrgUser } from '../models/extended-org-user.model';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    @Inject(ROOT_URL) private rootUrl: BehaviorSubject<string>,
    private authService: AuthService,
  ) {
    this.rootUrl.subscribe((apiEndpoint) => {
      try {
        const enableMixpanel = environment.ENABLE_MIXPANEL;
        if (enableMixpanel === 'true' && apiEndpoint) {
          const config: Partial<Config> = {
            debug: false,
            track_pageview: false,
            persistence: 'localStorage',
          };

          const useMixpanelProxy = environment.USE_MIXPANEL_PROXY;
          if (useMixpanelProxy === 'true') {
            config.api_host = `${apiEndpoint}/mixpanel`;
          }

          mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, config);
        }
      } catch (e) {}
    });
  }

  private identifyUser(email: string, properties?: Record<string, string>) {
    try {
      mixpanel.identify(email);
      if (properties) {
        mixpanel.people.set(properties);
      }
    } catch (e) {}
  }

  private isDemoAccount(eou: ExtendedOrgUser): boolean {
    const email = eou.us.email.toLowerCase();
    const orgName = eou.ou.org_name.toLowerCase();
    const keywords = ['demo', 'test', 'fyle'];

    return keywords.some((keyword) => email.includes(keyword) || orgName.includes(keyword));
  }

  private getUserProperties() {
    const properties: Record<string, string | boolean> = {};
    const eou = this.authService.getEou();
    if (eou?.ou) {
      try {
        const distinctId = mixpanel.get_distinct_id();
        if (distinctId !== eou.us.email) {
          this.identifyUser(eou.us.email, {
            $name: eou.us.full_name,
            $email: eou.us.email,
          });
        }
      } catch (e) {}
      properties['User Id'] = eou.us.id;
      properties['Org Id'] = eou.ou.org_id;
      properties['Org User Id'] = eou.ou.id;
      properties['Org Currency'] = eou.org.currency;
      properties['Is Demo Account'] = this.isDemoAccount(eou);
    }
    return properties;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  eventTrack(action: string, properties: Record<string, any>): void {
    if (properties.Asset !== 'webapp') {
      properties.Asset = 'webapp';
    }
    extend(properties, this.getUserProperties());
    try {
      mixpanel.track(action, properties);
    } catch (e) {}
  }

  // external APIs
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onSignup(email: string, properties: Record<string, any>) {
    this.identifyUser(email);
    this.eventTrack('Signup', properties);
  }

  // external APIs
  /* eslint-disable @typescript-eslint/no-explicit-any */
  canSignup(email: string, properties: Record<string, any> = {}) {
    this.identifyUser(email);
    this.eventTrack('Signup Started', properties);
  }

  // new function name
  updateSegmentProfile(email: string, data: Record<string, string>) {
    try {
      const distinctId = mixpanel.get_distinct_id();
      if (distinctId !== email) {
        this.identifyUser(email, data);
      } else {
        mixpanel.people.set(data);
      }
    } catch (e) {}
  }

  // external APIs
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onSignin(email: string, properties: Record<string, any> = {}) {
    this.identifyUser(email);
    this.eventTrack('Signin', properties);
  }

  // signout event
  onSignOut(properties: Record<string, any> = {}) {
    this.eventTrack('Sign Out', properties);
  }

  // When user completes account setup journey
  setupComplete(properties: Record<string, any> = {}) {
    this.eventTrack('Setup Complete', properties);
  }

  // When user clicks on schedule demo
  onClickScheduleDemo(properties: Record<string, string> = {}): void {
    this.eventTrack('Clicked schedule demo', properties);
  }

  // activated event
  activated(properties: Record<string, any> = {}) {
    this.eventTrack('Activated', properties);
  }

  // reset password event
  resetPassword(properties: Record<string, any> = {}) {
    this.eventTrack('Reset Password', properties);
  }

  onSwitchOrg(properties: Record<string, any>) {
    this.eventTrack('Switch Org', properties);
  }

  // email verified event
  emailVerified(properties: Record<string, any> = {}) {
    this.eventTrack('Email Verified', properties);
  }

  // Active organization display card tracking
  activeOrganizationDisplayCard(properties: Record<string, any> = {}) {
    this.eventTrack('Click on Active Organization Card under switch org page', properties);
  }

  deletedOldLocalStorageKeys(properties: Record<string, any> = {}) {
    this.eventTrack('Deleted Old Local Storage Keys', properties);
  }

  userAlreadyMigratedToNewLocalStorage(properties: Record<string, any> = {}) {
    this.eventTrack('User already on new local storage', properties);
  }

  migratedToNewLocalStorage(properties: Record<string, any> = {}) {
    this.eventTrack('Migrated to new local storage', properties);
  }
}
